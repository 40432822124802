<template>
  <div id="app">
    <header-info></header-info>
    <router-view />
  </div>
</template>

<script>
import HeaderInfo from '@/components/HeaderInfo'
export default {
  name: 'App',
  components: {
    HeaderInfo
  }
}
</script>

<style lang="scss">
#app {
  padding-bottom: 20px;
}
</style>
