<template>
  <div class="image-area d-flex justify-content-between">
    <div class="image-list d-flex justify-content-between" ref="imageList">
      <div
        class="switch-btns prev-btn"
        v-show="showPrevBtn()"
        @click="firstImageIndex--"
      >
        <i class="fas fa-chevron-left"></i>
      </div>

      <template v-for="(image, key) in imageList">
        <div class="image-block" :key="key" v-if="key >= firstImageIndex">
          <div
            class="image-container theme-container"
            :class="{ inactive: !image }"
          >
            <div
              class="theme-content"
              :style="{
                'background-image': 'url(' + image + ')',
                cursor: image ? 'pointer' : ''
              }"
              @click="$emit('onImageSelected', key)"
            ></div>
          </div>
          <div class="btn-container text-end">
            <span v-if="image" @click="$emit('onImageDownloadClicked', key)">
              <i class="image-btns fas fa-download"></i>
            </span>
            <span
              v-if="image"
              class="white-hover-icon"
              @click="$emit('onImageRemoveClicked', key)"
            >
              <img
                src="@/assets/close_blue.svg"
                class="btn-icon blue-icon mx-auto"
              />
              <img
                src="@/assets/close.svg"
                class="btn-icon white-icon mx-auto"
              />
            </span>
            <span v-else>&nbsp;</span>
          </div>
        </div>
      </template>

      <div
        class="switch-btns next-btn"
        v-show="showNextBtn()"
        @click="firstImageIndex++"
      >
        <i class="fas fa-chevron-right"></i>
      </div>
    </div>
    <div class="control-area d-flex flex-column justify-content-around">
      <div
        class="btn btn-theme d-flex align-items-center"
        @click="$emit('onImageRemoveClicked', -1)"
      >
        <div class="w-25">
          <img
            src="@/assets/close_blue.svg"
            class="btn-icon blue-icon mx-auto"
          />
          <img src="@/assets/close.svg" class="btn-icon white-icon mx-auto" />
        </div>
        <div class="align-self-center w-50">清除全部截圖</div>
      </div>
      <div
        class="btn btn-theme d-flex align-items-center"
        :class="{
          'theme-line': imageList[0]
        }"
        @click="$emit('onDownloadAllClicked')"
      >
        <div class="w-25 text-center">
          <img
            src="@/assets/burst_mode_blue.svg"
            class="btn-icon blue-icon mx-auto"
          />
          <img
            src="@/assets/burst_mode.svg"
            class="btn-icon white-icon mx-auto"
          />
        </div>
        <div class="align-self-center w-50">打包下載</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: { images: Array },
  name: 'CapturedImageList',
  mounted() {
    this.init()
  },
  watch: {
    images: function () {
      this.init()
    }
  },
  data() {
    return {
      imageContainWidth: 240,
      imageList: [],
      firstImageIndex: 0
    }
  },
  methods: {
    init: function () {
      //識別是否要移動圖片到最後一張
      const moveFirstImageIndex =
        this.imageList.length + 1 === this.images.length

      //計算實際寬度，取得可放置多少圖片
      const areaWidth = this.$refs.imageList.clientWidth
      this.imageList.splice(0, this.imageList.length)

      for (let image of this.images) {
        this.imageList.push(image)
      }

      while (areaWidth > this.imageContainWidth * this.imageList.length) {
        this.imageList.push(null)
      }

      //計算可放置多少圖片
      const maxImages = Math.ceil(areaWidth / this.imageContainWidth)

      if (moveFirstImageIndex && this.showNextBtn()) {
        this.firstImageIndex = this.imageList.length - maxImages
      }

      if (this.firstImageIndex > this.imageList.length - maxImages) {
        this.firstImageIndex = this.imageList.length - maxImages
      }
    },
    showPrevBtn: function () {
      return this.firstImageIndex > 0
    },
    showNextBtn: function () {
      if (!this.$refs.imageList) {
        return false
      }

      //計算實際寬度，取得可放置多少圖片
      const areaWidth = this.$refs.imageList.clientWidth
      const maxImages = Math.ceil(areaWidth / this.imageContainWidth)

      return this.firstImageIndex + maxImages < this.imageList.length
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/variables.scss';
$image-width: 210px;
$image-height: 123.625px;

.image-list {
  width: calc(100% - 240px);
  padding: 8px 0;
  overflow: hidden;
  position: relative;

  .image-block {
    padding: 5px 10px;

    .image-container {
      width: $image-width;
      height: $image-height;
      padding: 4px;
      .theme-content {
        width: 100%;
        height: 100%;
        background-color: $light-bg-color;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }

    .btn-container {
      width: $image-width;
      padding: 6px 0;

      .image-btns {
        color: $bright-text-color;
        cursor: pointer;
        margin-left: 20px;

        &:hover {
          color: $text-color;
        }

        &.secondary-btns {
          color: $text-color;

          &:hover {
            color: $bright-text-color;
          }
        }
      }
    }
  }

  .switch-btns {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 24px;
    margin: 13px 0 49px;
    background-color: $dark-theme-line-color;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }

  .prev-btn {
    left: 0;
  }

  .next-btn {
    right: 0;
  }
}

.control-area {
  width: 240px;
  padding: 25px 20px 61px;
}

.btn.btn-theme {
  .btn-icon {
    width: 18px;
  }
  .blue-icon {
    display: none;
  }

  .white-icon {
    display: block;
  }

  &.theme-line {
    .blue-icon {
      display: block;
    }

    .white-icon {
      display: none;
    }

    &:hover {
      .blue-icon {
        display: none;
      }

      .white-icon {
        display: block;
      }
    }
  }

  &:hover {
    .blue-icon {
      display: block;
    }

    .white-icon {
      display: none;
    }
  }
}

.white-hover-icon {
  margin-left: 20px;
  cursor: pointer;

  .btn-icon {
    width: 18px;
  }
  .blue-icon {
    display: none;
  }

  .white-icon {
    display: inline-block;
  }

  &.theme-line {
    .blue-icon {
      display: inline-block;
    }

    .white-icon {
      display: none;
    }

    &:hover {
      .blue-icon {
        display: none;
      }

      .white-icon {
        display: block;
      }
    }
  }

  &:hover {
    .blue-icon {
      display: inline-block;
    }

    .white-icon {
      display: none;
    }
  }
}
</style>
