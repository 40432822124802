<template>
  <div id="header" class="d-flex justify-content-between">
    <router-link :to="{ name: 'Home' }">
      <img src="@/assets/vgh.svg" class="logo-img" />
    </router-link>

    <div class="timer d-flex justify-content-center flex-column">
      <div class="time d-flex">
        <div class="digits">
          <div>{{ timer | moment('HH') | substring(0, 1) }}</div>
          <div>{{ timer | moment('HH') | substring(1, 2) }}</div>
        </div>
        <div class="separator">:</div>
        <div class="digits">
          <div>{{ timer | moment('mm') | substring(0, 1) }}</div>
          <div>{{ timer | moment('mm') | substring(1, 2) }}</div>
        </div>
        <div class="separator">:</div>
        <div class="digits">
          <div>{{ timer | moment('ss') | substring(0, 1) }}</div>
          <div>{{ timer | moment('ss') | substring(1, 2) }}</div>
        </div>
      </div>

      <div class="date-week d-flex justify-content-between">
        <div class="date">{{ timer | moment('MM/DD/YYYY') }}</div>
        <div class="week">{{ timer | moment('dddd') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderInfo',
  created() {
    const that = this

    setInterval(function () {
      that.timerStart()
    }, 1000)
  },
  data() {
    return {
      timer: new Date()
    }
  },
  methods: {
    timerStart: function () {
      this.timer = new Date()
    }
  },
  filters: {
    substring(str, start, end) {
      return ('' + str).substring(start, end)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Orbitron&display=swap');

#header {
  height: 100px;
  padding: 26px $padding-x;
}

.logo-img {
  height: 100%;
}

.timer {
  .time {
    width: 125px;
    font-size: 24px;
    line-height: 24px;
    font-family: 'Orbitron', sans-serif;

    .digits {
      width: calc(110px / 3);
      display: flex;

      & > * {
        width: 50%;
        text-align: right;
      }
    }

    .separator {
      width: 6px;
      text-align: center;
    }
  }

  .date-week {
    font-size: 13px;
    font-weight: 100;
    letter-spacing: 0.8px;
  }
}
</style>
