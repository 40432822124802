<template>
  <div class="home">
    <div class="title">
      <img src="@/assets/data_usage.svg" class="title-icon" />
      CT 影片
      <div class="line-h">
        <div class="left"></div>
        <div class="center"></div>
        <div class="right"></div>
      </div>
    </div>

    <div class="video-section">
      <div class="upload-area">
        <video
          v-if="uploadedVideoUrl"
          ref="uploadedVideo"
          :src="uploadedVideoUrl"
          controls
          class="uploaded-video"
        ></video>

        <vue2-dropzone
          v-else
          ref="dropzone"
          id="dropzone"
          class="align-self-center"
          :options="dropzoneOptions"
          :useCustomSlot="true"
          @vdropzone-success="onUploaded"
        >
          <div class="dropzone-custom-content">
            <div class="dropzone-custom-title bright-text-theme">
              <img src="@/assets/upload_file.svg" class="upload-file-img" />
            </div>
            <div class="subtitle light-text-theme">
              <div>拖曳或點擊上傳CT影片</div>
            </div>
          </div>
        </vue2-dropzone>
      </div>
      <div class="capture-area">
        <div
          class="current-image"
          :style="{
            'background-image': 'url(' + currentImage + ')'
          }"
        >
          <div class="capture-image-btn" @click="captureVideo">
            <img src="@/assets/camera_alt.svg" class="camera-img" />
          </div>
        </div>
      </div>
    </div>

    <div class="title">
      <img src="@/assets/center_focus_strong.svg" class="title-icon" />
      CT 畫面擷取
      <div class="line-h">
        <div class="left"></div>
        <div class="center"></div>
        <div class="right"></div>
      </div>
    </div>

    <captured-image-list
      :images="capturedImages"
      @onImageSelected="onImageSelected"
      @onImageDownloadClicked="onImageDownloadClicked"
      @onImageRemoveClicked="onImageRemoveClicked"
      @onDownloadAllClicked="onDownloadAllClicked"
    ></captured-image-list>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import CapturedImageList from '@/components/CapturedImageList'
var JSZip = require('jszip')

export default {
  name: 'Home',
  components: {
    vue2Dropzone,
    CapturedImageList
  },
  mounted() {
    window.uploadSimulate = this.onUploaded
  },
  data() {
    return {
      dropzoneOptions: {
        url: '/api/edah_api',
        method: 'post',
        paramName: 'filename',
        acceptedFiles: 'video/mp4,video/x-m4v,video/*',
        maxFilesize: 100,
        maxFiles: 1,
        thumbnailMethod: 'contain',
        headers: {},
        init: function () {
          this.on('success', function (file) {
            this.removeFile(file)
          })

          this.on('error', function () {
            var errorDisplay = document.querySelectorAll(
              '[data-dz-errormessage]'
            )
            errorDisplay[errorDisplay.length - 1].innerHTML = '上傳失敗'

            window.uploadSimulate()
          })

          this.on('cancel', function (file) {
            this.removeFile(file)
          })
        }
      },
      uploadedVideoUrl: null, //已上傳的影片網址
      currentImage: null, //目前顯示的照片
      capturedImages: [] //已擷取的照片清單
    }
  },
  methods: {
    //檔案上傳完成
    onUploaded: function (file, response) {
      if (!response) {
        response = '/test.mp4'
      }

      this.uploadedVideoUrl = response
    },
    //從影片截圖
    captureVideo: function () {
      if (!this.uploadedVideoUrl) {
        return
      }

      const video = this.$refs.uploadedVideo
      let canvas = document.createElement('canvas')
      canvas.width = video.videoWidth
      canvas.height = video.videoHeight
      let canvasContext = canvas.getContext('2d')
      canvasContext.drawImage(video, 0, 0)

      this.currentImage = canvas.toDataURL('image/jpeg')
      this.capturedImages.push(this.currentImage)
    },
    //選取圖片
    onImageSelected: function (imageId) {
      if (!this.capturedImages[imageId]) {
        return
      }

      this.currentImage = this.capturedImages[imageId]
    },
    //下載圖片
    onImageDownloadClicked: function (imageId) {
      if (!this.capturedImages[imageId]) {
        return
      }

      let now = new Date()
      let filename = 'image' + now.getTime() + '.jpg'

      this.download(filename, this.capturedImages[imageId])
    },
    //建立下載連結自動下載
    download: function (filename, data) {
      let a = document.createElement('a')
      a.href = data
      a.download = filename
      a.click()
    },
    //刪除圖片
    onImageRemoveClicked: function (imageId) {
      if (imageId === -1) {
        //全部刪除
        this.capturedImages.splice(0, this.capturedImages.length)
        return
      }

      if (!this.capturedImages[imageId]) {
        return
      }

      this.capturedImages.splice(imageId, 1)
    },
    //打包下載
    onDownloadAllClicked: function () {
      if (this.capturedImages.length === 0) {
        return
      }

      this.$swal.fire({
        title: '打包下載',
        text: '檔案正在打包中，稍後就會開始下載。',
        icon: 'info',
        confirmButtonText: '我知道了',
        confirmButtonClass: 'btn-theme theme-line'
      })

      const that = this
      var zip = new JSZip()

      let now = new Date()
      const filename = 'images' + now.getTime()

      for (let i in this.capturedImages) {
        //將data url 轉為 base64
        const basePic = this.capturedImages[i].replace(
          /^data:image\/(png|jpg|jpeg);base64,/,
          ''
        )

        let imageName = i
        imageName++
        imageName += '.jpg'

        zip.file(filename + '/' + imageName, basePic, {
          base64: true
        })
      }

      zip.generateAsync({ type: 'base64' }).then(
        function (base64) {
          that.download(
            filename + '.zip',
            'data:application/zip;base64,' + base64
          )
        },
        function (err) {
          console.log(err)
        }
      )
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/variables.scss';

.home {
  padding: 0 $padding-x;
}

.title {
  .title-icon {
    color: $bright-text-color;
    margin-right: 8px;
    margin-left: 8px;
  }
}

#dropzone {
  background-color: transparent;
}

.vue-dropzone {
  width: 100%;
  height: 100%;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed $light-text-color;
  border-radius: 0px;
  background-color: $light-bg-color;
}

.dropzone .dz-preview .dz-error-message {
  top: 80px;
}

.dropzone-custom-content {
  .subtitle {
    font-size: 14px;
    margin-top: 12px;
  }

  .upload-file-img {
    width: 36px;
  }
}

.video-section {
  display: flex;
  margin: 0 $reserved-margin-x;
  padding: 20px 0;

  & > * {
    width: 100%;
    /* 16:9 */
    height: calc((50vw - 60px) / 16 * 9);
    padding: 0 30px;
    overflow: hidden;
  }

  .upload-area {
    display: flex;
    align-items: center;
    justify-content: center;

    .uploaded-video {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .current-image {
    width: 100%;
    height: 100%;
    background-color: $light-bg-color;
    position: relative;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    .capture-image-btn {
      position: absolute;
      right: 15px;
      bottom: 15px;
      width: 60px;
      height: 60px;
      border-radius: 30px;
      background-color: $text-color;
      color: $body-bg-color;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0.5;

      .camera-img {
        width: 32px;
      }

      &:hover,
      &.active {
        background-color: $bright-text-color;
      }

      .icon {
        font-size: 26px;
      }
    }

    &:hover {
      .capture-image-btn {
        opacity: 1;
      }
    }
  }
}

.title-icon {
  width: 18px;
  vertical-align: text-top;
}
</style>
